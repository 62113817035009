import { Icon, Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  activatedGlobalProgrammes,
  data,
  onClickAction,
  ...props
}) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('optIn'),
        accessor: 'isActive',
        Cell: ({ row: { original } }) =>
          activatedGlobalProgrammes?.find(
            (item) => item.parentId === original.id
          ) ? (
            <Icon
              color={'success'}
              name='check_circle'
              px={1}
              tooltip='opted in'
            />
          ) : (
            <Icon
              color={'grey3'}
              name='check_circle'
              px={1}
              tooltip='not opted in'
              onClick={() =>
                onClickAction({
                  action: 'link',
                  id: original?.id,
                })
              }
            />
          ),
      },
    ],
    [t, activatedGlobalProgrammes, onClickAction]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
