import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  ProcessingSuspense,
  Text,
} from 'octiv-components';
import {
  useWaiversFindById,
  useWaiversFindDownload,
  useWaiversUpdateSign,
} from 'octiv-hooks/requests/Waivers';
import { downloadFile } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FormSign from './FormSign';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const {
    data: waiver,
    isFetching: isFetchingWaiver,
    refetch: getWaiverRequest,
  } = useWaiversFindById(
    { id },
    {
      initialData: {},
    }
  );

  const {
    isFetching: isFetchingWaiverDownload,
    refetch: getWaiverDownloadRequest,
  } = useWaiversFindDownload(
    {},
    {
      enabled: false,
      onSuccess: (res) => downloadFile(res.file),
    }
  );

  const { isLoading: isUpdatingWaiverSign, mutate: putWaiverSignRequest } =
    useWaiversUpdateSign(
      { id },
      {
        enabled: false,
        onSuccess: getWaiverRequest,
      }
    );

  return (
    <Container
      appBarProps={{
        title: t('signWaiver'),
        children: waiver?.signedOn && (
          <Box ml='auto'>
            <Button
              isLoading={isFetchingWaiverDownload}
              text={t('downloadWaiver')}
              onClick={() =>
                getWaiverDownloadRequest({ userId: waiver?.user?.id })
              }
            />
          </Box>
        ),
      }}
      isLoading={isFetchingWaiver}
    >
      {waiver?.signedOn && <Alert mb={4} title={t('waiverHasBeenSigned')} />}

      <Card mb={4} title={t('termsConditions')}>
        {isFetchingWaiver ? (
          <ProcessingSuspense />
        ) : (
          <Text
            as='div'
            dangerouslySetInnerHTML={{
              __html: waiver?.digitalTermsAndConditions,
            }}
          />
        )}
      </Card>

      {waiver?.id && !waiver?.signedOn && (
        <FormSign
          isLoading={isUpdatingWaiverSign}
          onSubmit={() => putWaiverSignRequest({ id })}
        />
      )}
    </Container>
  );
};
