import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useAddressesSearch = (input, options) => {
  return useQuery(
    ['addresses', 'search', input],
    () =>
      request({
        method: 'get',
        url: `/api/addresses/search`,
        params: {
          address: input?.address,
        },
      }),
    options
  );
};

export const useAddressesFindById = (input, options) => {
  return useQuery(
    ['addresses', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/addresses/${input.id}`,
      }),
    options
  );
};

export const useAddressesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/addresses/${data.type}/${data.id}`,
        data: {
          fullAddress: data.fullAddress,
          structuredAddress: data.structuredAddress,
          coordinates: data.coordinates,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAddressesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/addresses/${data.id}`,
        data: {
          fullAddress: data.fullAddress,
          structuredAddress: data.structuredAddress,
          coordinates: data.coordinates,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAddressesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/addresses/${data.id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] });

      options?.onSuccess?.(...res);
    },
  });
};
