import { Alert, Modal, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useToggle } from 'octiv-hooks';
import {
  useFinancesCreateStatementsBulkSend,
  useFinancesCreateUserInvoicesBulk,
} from 'octiv-hooks/requests/Finances';
import { useOnHoldUsersCreate } from 'octiv-hooks/requests/OnHoldUsers';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useScheduledUserActionsDelete } from 'octiv-hooks/requests/ScheduledUsersActions';
import {
  useUserContractsCreateBulkGenerate,
  useUserContractsCreateDownload,
  useUserContractsCreateDownloadAttachment,
  useUserContractsCreateSend,
  useUserContractsCreateSendAttachment,
  useUserContractsCreateSendCurrent,
} from 'octiv-hooks/requests/UserContracts';
import {
  useUserTenantsDeleteBulk,
  useUserTenantsSendWelcomeEmail,
  useUserTenantsUpdateBulkHighRisk,
  useUserTenantsUpdateBulkProgramme,
  useUserTenantsUpdateBulkStatus,
  useUserTenantsUpdatePaymentType,
} from 'octiv-hooks/requests/UserTenants';
import {
  useWaiversCreateDownload,
  useWaiversFindById,
  useWaiversUpdateBulkSend,
} from 'octiv-hooks/requests/Waivers';
import {
  downloadFile,
  getDateReadableDayMonthYear,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { views } from '../../userMember';
import FormOnHold from '../../userMember/overview/FormOnHold';
import FormChangeProgramme from './FormChangeProgramme';
import FormContractGenerate from './FormContractGenerate';
import FormInvoiceGenerate from './FormInvoiceGenerate';
import FormPaymentType from './FormPaymentType';
import FormSendStatement from './FormSendStatement';

const MembersWrapper = ({ children, selectedData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    tenant: { isGoCardless },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();

  const [
    toggleFormChangeProgramme,
    setToggleFormChangeProgramme,
    resetToggleFormChangeProgramme,
  ] = useToggle();
  const [
    toggleFormPaymentType,
    setToggleFormPaymentType,
    resetToggleFormPaymentType,
  ] = useToggle();
  const [
    toggleFormInvoiceGenerate,
    setToggleFormInvoiceGenerate,
    resetToggleFormInvoiceGenerate,
  ] = useToggle();
  const [
    toggleFormSendStatement,
    setToggleFormSendStatement,
    resetToggleFormSendStatement,
  ] = useToggle();
  const [
    toggleFormPlaceOnHold,
    setToggleFormPlaceOnHold,
    resetToggleFormPlaceOnHold,
  ] = useToggle();
  const [
    toggleFormContractGenerate,
    setToggleFormContractGenerate,
    resetToggleFormContractGenerate,
  ] = useToggle();
  const [toggleWaiver, setToggleWaiver, resetToggleWaiver] = useToggle();

  const programmesFind = useProgrammesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const userTenantsUpdateBulkStatus = useUserTenantsUpdateBulkStatus({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdateBulkHighRisk = useUserTenantsUpdateBulkHighRisk({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsDeleteBulk = useUserTenantsDeleteBulk({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateSend = useUserContractsCreateSend({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateSendCurrent = useUserContractsCreateSendCurrent({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateSendAttachment =
    useUserContractsCreateSendAttachment({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const userContractsCreateDownload = useUserContractsCreateDownload({
    onSuccess: (response) => downloadFile(response.file),
  });

  const userContractsCreateDownloadAttachment =
    useUserContractsCreateDownloadAttachment({
      onSuccess: (response) => downloadFile(response.file),
    });

  const userTenantsSendWelcomeEmail = useUserTenantsSendWelcomeEmail({
    onSuccess: () => {},
    meta: {
      useOnSuccessToast: true,
    },
  });

  const waiversFindById = useWaiversFindById(
    { id: toggleWaiver.data?.id },
    {
      enabled: !!toggleWaiver.data?.id,
      onSuccess: setToggleWaiver,
      onError: resetToggleWaiver,
    }
  );

  const waiversUpdateBulkSend = useWaiversUpdateBulkSend({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const waiversCreateDownload = useWaiversCreateDownload({
    onSuccess: (res) => downloadFile(res.file),
  });

  const scheduledUsersActionsDelete = useScheduledUserActionsDelete({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdateBulkProgramme = useUserTenantsUpdateBulkProgramme({
    onSuccess: resetToggleFormChangeProgramme,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdatePaymentType = useUserTenantsUpdatePaymentType({
    onSuccess: resetToggleFormPaymentType,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const financesCreateUserInvoicesBulk = useFinancesCreateUserInvoicesBulk({
    onSuccess: resetToggleFormInvoiceGenerate,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateBulkGenerate = useUserContractsCreateBulkGenerate({
    onSuccess: resetToggleFormContractGenerate,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const financesCreateStatementsBulkSend = useFinancesCreateStatementsBulkSend({
    onSuccess: resetToggleFormSendStatement,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onHoldUsersCreate = useOnHoldUsersCreate({
    onSuccess: resetToggleFormPlaceOnHold,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const getBulkDataIds = ({ forUserTenant }) =>
    selectedData.map((item) =>
      forUserTenant
        ? item?.userTenantId || item?.userTenant?.id || item?.id
        : item?.userId || item?.user?.id || item?.id
    );

  const onClickAction = ({
    action,
    isBulk = false,
    userTenantId,
    userId,
    contractId,
    waiverId,
    scheduledActionId,
  }) => {
    switch (action) {
      case 'view':
        setMemberModal({ userTenantId });
        break;

      case 'sendComm':
        navigate(`/comms/create`, {
          state: {
            recipients: {
              members: selectedData?.map((item) => ({
                id: item?.id,
                name: item?.user?.name || item?.name,
                surname: item?.user?.surname || item?.surname,
              })),
            },
          },
        });
        break;

      case 'sendWelcomeEmail':
        userTenantsSendWelcomeEmail.mutate({
          users: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
        });
        break;

      case 'invoiceGenerate':
        setToggleFormInvoiceGenerate({
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      case 'contractGenerate':
        setToggleFormContractGenerate({
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      case 'changeProgramme':
        setToggleFormChangeProgramme({
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      case 'paymentType':
        setToggleFormPaymentType({
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      case 'markHighRisk':
        userTenantsUpdateBulkHighRisk.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          isHighRisk: 1,
        });
        break;

      case 'removeHighRisk':
        userTenantsUpdateBulkHighRisk.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          isHighRisk: 0,
        });
        break;

      case 'activate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          statusId: 2,
        });
        break;

      case 'placeOnHold':
        setToggleFormPlaceOnHold({
          type: 'placeOnHold',
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      case 'suspend':
        userTenantsUpdateBulkStatus.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          statusId: 3,
        });
        break;

      case 'deactivate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          statusId: 4,
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          userTenantsDeleteBulk.mutate({
            userTenantIds: isBulk
              ? getBulkDataIds({ forUserTenant: true })
              : [userTenantId],
          });
        }
        break;

      case 'createContract':
        setMemberModal({ userTenantId, initialView: views.CONTRACTS });
        break;

      case 'sendContract':
        if (isBulk) {
          userContractsCreateSendCurrent.mutate({
            userIds: getBulkDataIds({ forUserTenant: false }),
          });
        } else {
          userContractsCreateSend.mutate({ id: contractId });
        }
        break;

      case 'sendContractAttachment':
        userContractsCreateSendAttachment.mutate({ id: contractId });
        break;

      case 'downloadContract':
        userContractsCreateDownload.mutate({ id: contractId });
        break;

      case 'downloadContractAttachment':
        userContractsCreateDownloadAttachment.mutate({ id: contractId });
        break;

      case 'viewWaiver':
        setToggleWaiver({ data: { id: waiverId } });
        break;

      case 'sendWaiverOriginal':
        waiversUpdateBulkSend.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          isLatest: false,
        });
        break;

      case 'sendWaiverLatest':
        waiversUpdateBulkSend.mutate({
          userIds: isBulk ? getBulkDataIds({ forUserTenant: false }) : [userId],
          isLatest: true,
        });
        break;

      case 'downloadWaiver':
        waiversCreateDownload.mutate({ userId });
        break;

      case 'deleteScheduledAction':
        scheduledUsersActionsDelete.mutate({ id: scheduledActionId });
        break;

      case 'sendStatement':
        setToggleFormSendStatement({
          data: {
            userIds: isBulk
              ? getBulkDataIds({ forUserTenant: false })
              : [userId],
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleFormInvoiceGenerate.isVisible && (
        <Modal
          isSidebar
          title={t('generateInvoice')}
          onClose={resetToggleFormInvoiceGenerate}
        >
          <FormInvoiceGenerate
            initialValues={toggleFormInvoiceGenerate.data}
            isLoading={financesCreateUserInvoicesBulk.isLoading}
            onSubmit={financesCreateUserInvoicesBulk.mutate}
          />
        </Modal>
      )}

      {toggleFormContractGenerate.isVisible && (
        <Modal
          isSidebar
          title={t('generateContract')}
          onClose={resetToggleFormContractGenerate}
        >
          <FormContractGenerate
            initialValues={toggleFormContractGenerate.data}
            isLoading={userContractsCreateBulkGenerate.isLoading}
            onSubmit={userContractsCreateBulkGenerate.mutate}
          />
        </Modal>
      )}

      {toggleFormChangeProgramme.isVisible && (
        <Modal
          isSidebar
          title={t('changeProgramme')}
          onClose={resetToggleFormChangeProgramme}
        >
          <FormChangeProgramme
            initialValues={toggleFormChangeProgramme.data}
            isFetchingProgrammes={programmesFind.isFetching}
            isLoading={userTenantsUpdateBulkProgramme.isLoading}
            programmes={programmesFind.data?.data}
            onSubmit={userTenantsUpdateBulkProgramme.mutate}
          />
        </Modal>
      )}

      {toggleFormPaymentType.isVisible && (
        <Modal
          isSidebar
          title={t('changePaymentType')}
          onClose={resetToggleFormPaymentType}
        >
          <FormPaymentType
            initialValues={toggleFormPaymentType.data}
            isGoCardless={isGoCardless}
            isLoading={userTenantsUpdatePaymentType.isLoading}
            onSubmit={userTenantsUpdatePaymentType.mutate}
          />
        </Modal>
      )}

      {toggleFormSendStatement.isVisible && (
        <Modal
          isSidebar
          title={t('sendStatement')}
          onClose={resetToggleFormSendStatement}
        >
          <FormSendStatement
            initialValues={toggleFormSendStatement.data}
            isLoading={financesCreateStatementsBulkSend.isLoading}
            onSubmit={financesCreateStatementsBulkSend.mutate}
          />
        </Modal>
      )}

      {toggleFormPlaceOnHold.isVisible && (
        <Modal
          isSidebar
          title={t('placeOnHold')}
          onClose={resetToggleFormPlaceOnHold}
        >
          <FormOnHold
            initialValues={toggleFormPlaceOnHold.data}
            isLoading={onHoldUsersCreate.isLoading}
            onSubmit={onHoldUsersCreate.mutate}
          />
        </Modal>
      )}

      {toggleWaiver.isVisible && (
        <Modal isSidebar title={t('waiver')} onClose={resetToggleWaiver}>
          <Alert
            mb={4}
            subtitle={
              waiversFindById.data?.status === 'sent'
                ? t('waiverHasNotBeenSigned')
                : `${t('waiverHasBeenSigned')}: ${getDateReadableDayMonthYear({
                    date: waiversFindById.data?.signedOn,
                  })}`
            }
            variant={
              waiversFindById.data?.status === 'sent' ? 'warning' : 'success'
            }
          />

          <Text
            as='div'
            dangerouslySetInnerHTML={{
              __html: waiversFindById.data?.digitalTermsAndConditions,
            }}
          />
        </Modal>
      )}

      {children({
        onClickAction,
        programmesFind,
        isContainerLoading:
          financesCreateStatementsBulkSend.isLoading ||
          financesCreateUserInvoicesBulk.isLoading ||
          onHoldUsersCreate.isLoading ||
          scheduledUsersActionsDelete.isLoading ||
          userContractsCreateBulkGenerate.isLoading ||
          userContractsCreateDownload.isLoading ||
          userContractsCreateDownloadAttachment.isLoading ||
          userContractsCreateSend.isFetching ||
          userContractsCreateSendAttachment.isFetching ||
          userContractsCreateSendCurrent.isFetching ||
          userTenantsDeleteBulk.isLoading ||
          userTenantsUpdateBulkHighRisk.isLoading ||
          userTenantsUpdateBulkProgramme.isLoading ||
          userTenantsUpdateBulkStatus.isLoading ||
          userTenantsUpdatePaymentType.isLoading ||
          waiversCreateDownload.isLoading ||
          waiversFindById.isFetching ||
          waiversUpdateBulkSend.isLoading,
      })}
    </>
  );
};

export default MembersWrapper;
