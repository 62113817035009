import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const usePackagesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...rest } = input || {};

  return useQuery(
    ['packages', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            packageId: filter.packageId,
            isActive: filter.isActive,
            isForSignUp: filter.isForSignUp,
            isForBuyPackages: filter.isForBuyPackages,
            isHidden: filter.isHidden,
            tagIds: filter.tagIds,
            typeId: filter.typeId,
          },
          ...paging,
          ...rest,
        },
      }),
    options
  );
};

export const usePackagesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};
      const isDefaultPeriodTypeUndefined = data.defaultPeriodType === undefined;

      return request({
        method: 'post',
        url: `/api/packages`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          description: data.description,
          price: data.price,
          healthProviderPrice: Number(data.healthProviderPrice),
          topupPrice: data.topupPrice,
          type: data.typeId,
          limit: Number(data.limit),
          defaultPeriod: isDefaultPeriodTypeUndefined
            ? undefined
            : Number(data.defaultPeriod) || undefined,
          defaultPeriodInterval: isDefaultPeriodTypeUndefined
            ? undefined
            : data.defaultPeriodType,
          isDisplayed: Number(data.isDisplayed),
          isDisplayOnBuyPackages: Number(data.isDisplayOnBuyPackages),
          isHidden: data.isHidden,
          tagIds: data.tagIds,
          priority: data.priority,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['packages', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages/${id}`,
      }),
    options
  );
};

export const usePackagesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};
      const isDefaultPeriodTypeUndefined = data.defaultPeriodType === undefined;

      return request({
        method: 'put',
        url: `/api/packages/${id}`,
        data: {
          name: data.name,
          description: data.description,
          price: data.price,
          // TODO:
          topupPrice: data.topupPrice || null,
          typeId: data.typeId,
          limit: data.limit,
          defaultPeriod: isDefaultPeriodTypeUndefined
            ? undefined
            : Number(data.defaultPeriod) || undefined,
          defaultPeriodInterval: isDefaultPeriodTypeUndefined
            ? undefined
            : data.defaultPeriodType,
          isDisplayed: data.isDisplayed,
          isDisplayOnBuyPackages: data.isDisplayOnBuyPackages,
          isHidden: data.isHidden,
          priority: data.priority,
          tagIds: data.tagIds,
          healthProviderPrice: Number(data.healthProviderPrice),
          isActive: data.isActive,
          lateCancellationFee: Number(data.lateCancellationFee) || 0,
          noShowFee: Number(data.noShowFee) || 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/packages/${id}/activate-deactivate`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesUpdateBulkActions = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/packages/actions`,
        data: {
          tenantId: data.tenantId || tenantId,
          packageIds: data.packageIds,
          lateCancellationFee: Number(data.lateCancellationFee) || 0,
          noShowFee: Number(data.noShowFee) || 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesFindByIdClasses = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['packages', 'findByIdClasses', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages/${id}/classes`,
        params: {
          filter: {
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const usePackagesUpdateClasses = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/packages/${id}/classes`,
        data: { locationId: data.locationId, classIds: data.classIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesFindByIdProgrammes = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['packages', 'findByIdProgrammes', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages/${id}/programmes`,
      }),
    options
  );
};

export const usePackagesUpdateProgrammes = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/packages/${id}/programmes`,
        data: { programmeIds: data.programmeIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePackagesFindByIdLocations = (input, options) => {
  const { id, tenantId, paging = undefined } = input || {};

  return useQuery(
    ['packages', 'findByIdLocations', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages/${id}/locations`,
        params: {
          filter: {
            tenantId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const usePackagesUpdateLocations = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/packages/${id}/locations`,
        data: { locationIds: data.locationIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['packages'] });

      options?.onSuccess?.(...res);
    },
  });
};
