import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { ThemeContext } from 'styled-components';

const AsyncSelectInput = ({
  loadOptions = [],
  onBlur,
  value: valueProp,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: 0,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      borderRadius: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.grey4}`,
      boxShadow: `1px 2px 12px 0px ${
        theme.isDark ? theme.colors.dark50 : theme.colors.dark10
      }`,
      zIndex: 100,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: `${theme.sizes[6]} ${theme.sizes[3]} ${theme.sizes[1]} ${theme.sizes[3]}`,
      height: theme.sizes[13],
      overflow: 'scroll',
    }),
    singleValue: (provided) => ({
      ...provided,
      position: undefined,
      margin: 0,
      top: undefined,
      transform: undefined,
      cursor: 'not-allowed',
    }),
    option: (provided, { isSelected, isFocused, isDisabled }) => ({
      ...provided,
      color: isSelected ? theme.colors.button : theme.colors.font,
      backgroundColor: isSelected
        ? theme.colors.primary
        : isFocused
        ? theme.colors.grey5
        : theme.colors.grey6,
      opacity: isDisabled ? 0.5 : 1,
      ':hover': {
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isSelected ? theme.colors.primary : theme.colors.grey5,
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: theme.colors.grey6,

      ':hover': {
        cursor: 'pointer',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      position: undefined,
      margin: 0,
      top: undefined,
      transform: undefined,
      color: theme.colors.grey2,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <AsyncSelect
      {...props}
      cacheOptions
      className='react-select'
      loadOptions={loadOptions}
      styles={customStyles}
      theme={(selectTheme) => ({
        ...selectTheme,
        colors: {
          ...selectTheme.colors,

          // Input Font
          neutral80: theme.colors.font,

          // Background
          neutral0: theme.colors.grey6,

          // Drop down arrow
          neutral20: theme.colors.font,
          neutral40: theme.colors.font,
          neutral60: theme.colors.font,

          // Multi background
          neutral10: theme.colors.grey3,

          // Other
          danger: theme.colors.danger,
          dangerLight: theme.colors.danger20,
        },
      })}
      onBlur={onBlur}
    />
  );
};

export default AsyncSelectInput;
