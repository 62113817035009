import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useFinancesFindCreditNotes = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findCreditNotes', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/credit-notes`,
        params: {
          filter: {
            between: `${filter.startDate},${filter.endDate}`,
            locationId: filter.locationId,
            tenantId,
            sentStatus: filter.sentStatus,
            search: filter.search,
            sort: filter.sort,
            order: filter.order,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesCreateCreditNotes = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/credit-notes`,
        data: {
          userId: data.userId,
          description: data.description,
          dueOn: data.dueOn,
          amount: data.amount,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindCreditNotesExport = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findCreditNotesExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/credit-notes/export`,
        params: {
          filter: {
            tenantId,
            between: `${filter.startDate},${filter.endDate}`,
            locationId: filter.locationId,
            sentStatus: filter.sentStatus,
            search: filter.search,
            sort: filter.sort,
            order: filter.order,
          },
        },
      }),
    options
  );
};

export const useFinancesFindByIdCreditNotes = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdCreditNotes', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/credit-notes/${id}`,
      }),
    options
  );
};

export const useFinancesUpdateCreditNotes = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/credit-notes/${id}`,
        data: {
          description: data.description,
          dueOn: data.dueOn,
          amount: data.amount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindDiscounts = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findDiscounts', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/discounts`,
        params: {
          filter: {
            tenantId: filter?.tenantId || tenantId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesCreateDiscounts = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/discounts`,
        data: {
          name: data.name,
          description: data.description,
          type: data.type,
          amount: data.amount,
          tenantId: data?.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindByIdDiscounts = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdDiscounts', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/discounts/${id}`,
      }),
    options
  );
};

export const useFinancesUpdateDiscounts = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/discounts/${id}`,
        data: {
          name: data.name,
          description: data.description,
          type: data.type,
          amount: data.amount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteDiscounts = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/discounts/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindTenantInvoices = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['finances', 'findTenantInvoices', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-invoices`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
            tenantId,
            sentStatus: filter.sentStatus,
            search: filter.search,
            sort: filter.sort,
            order: filter.order,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesCreateTenantInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/tenant-invoices`,
        data: {
          locationId: data.locationId,
          description: data.description,
          dueOn: data.dueOn,
          status: data.status,
          paymentType: data.paymentType,
          paymentReference: data.paymentReference,
          lineItems: data.lineItems,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindByIdTenantInvoicesSend = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdTenantInvoicesSend', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-invoices/send/${id}`,
      }),
    options
  );
};

export const useFinancesFindByIdTenantInvoicesDownload = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdTenantInvoicesDownload', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-invoices/download/${id}`,
      }),
    options
  );
};

export const useFinancesFindTenantInvoicesExport = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findTenantInvoicesExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-invoices/export`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
            tenantId: filter.tenantId || tenantId,
            sentStatus: filter.sentStatus,
            search: filter.search,
            sort: filter.sort,
            order: filter.order,
          },
        },
      }),
    options
  );
};

export const useFinancesFindByIdTenantInvoices = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdTenantInvoices', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-invoices/${id}`,
      }),
    options
  );
};

export const useFinancesUpdateTenantInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/tenant-invoices/${id}`,
        data: {
          description: data.description,
          dueOn: data.dueOn,
          lineItems: data.lineItems,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteTenantInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/tenant-invoices/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindTenantPayments = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['finances', 'findTenantPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-payments`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            type: filter.type,
            startDate: filter.startDate,
            endDate: filter.endDate,
            search: filter.search,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesCreateTenantPayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/tenant-payments`,
        data: {
          tenantInvoiceId: data.tenantInvoiceId,
          date: data.date,
          type: data.type,
          reference: data.reference,
          amount: data.amount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindByIdTenantPayments = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdTenantPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/tenant-payments/${id}`,
      }),
    options
  );
};

export const useFinancesUpdateTenantPayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/tenant-payments/${id}`,
        data: {
          date: data.date,
          type: data.type,
          reference: data.reference,
          amount: data.amount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteTenantPayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/tenant-payments/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindPaymentGatewaysPayfastDetails = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findPaymentGatewaysPayfastDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/payment-gateways/payfast-details`,
        params: {
          tenantId,
        },
      }),
    options
  );
};

export const useFinancesDeletePaymentGatewaysPayfastDetails = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/payment-gateways/payfast-details`,
        data: { locationId: data.locationId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindGoCardlessMandates = (input, options) => {
  const { filter = {}, paging = {}, include = undefined } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findGoCardlessMandates', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/mandates`,
        params: {
          filter: {
            locationId: filter.locationId,
            mandateStatus: filter.mandateStatus,
            tenantId,
          },
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesFindGoCardlessMandatesMemberOnboardingLink = (
  input,
  options
) => {
  // const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findGoCardlessMandatesMemberOnboardingLink', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/mandates/on-boarding-link`,
        params: {
          tenantId: input?.tenantId,
          userId: input?.userId,
        },
      }),
    options
  );
};

export const useFinancesUpdateGoCardlessMandatesSendOnBoardingLink = (
  options
) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/finances/go-cardless/mandates/send-on-boarding-link`,
        data: { userIds: data.userIds, tenantId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindGoCardlessMandatesImport = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { filter = {} } = input || {};
      return request({
        method: 'put',
        url: `/api/finances/go-cardless/mandates/import-mandates/${filter.locationId}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteGoCardlessMandates = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/go-cardless/mandates/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindGoCardlessPaymentsPayouts = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findGoCardlessPaymentsPayouts', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/payments/payouts`,
        params: {
          locationId: filter.locationId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          limit: filter.limit,
          after: filter.after,
          before: filter.before,
        },
      }),
    options
  );
};

export const useFinancesFindGoCardlessPaymentsPayoutsItems = (
  input,
  options
) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findGoCardlessPaymentsPayoutsItems', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/payments/payouts-items`,
        params: {
          locationId: filter.locationId,
          payoutId: filter.payoutId,
          limit: filter.limit,
          after: filter.after,
          before: filter.before,
        },
      }),
    options
  );
};

export const useFinancesFindByIdGoCardlessPayments = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['finances', 'findByIdGoCardlessPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/payments/${id}`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useFinancesCreateGoCardlessMandateCallback = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/go-cardless/mandate-callback`,
        data: { redirectFlowId: data.redirectFlowId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateGoCardlessMerchantOauthCallback = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      // TODO: move to finances "hooks"
      return request({
        method: 'get',
        url: `/api/finances/go-cardless/merchant-oauth-callback`,
        data: { code: data.code, state: data.state },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindUserInvoices = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['finances', 'findUserInvoices', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices`,
        params: {
          filter: {
            type: filter.type,
            paymentType: filter.paymentType,
            locationId: filter.locationId,
            tenantId,
            packageId: filter.packageId,
            between: `${filter.startDate},${filter.endDate}`,
            sentStatus: filter.sentStatus,
            search: filter.search,
            status: filter.status,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useFinancesCreateUserInvoices = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices`,
        data: {
          tenantId,
          userId: data.userTenantId,
          description: data.description,
          dueOn: data.dueOn,
          status: data.status,
          paymentType: data.paymentType,
          paymentReference: data.paymentReference,
          lineItems: data.lineItems,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesGenerateLead = (options) => {
  const queryClient = useQueryClient();
  const { facilityId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/generate/lead`,
        data: {
          tenantId: facilityId,
          amount: data.amount,
          userId: data.userTenantId,
          description: data.description,
          dueOn: data.dueOn,
          status: data.status,
          paymentType: data.paymentType,
          paymentReference: data.paymentReference,
          lineItems: data.lineItems,
          isSend: data.isSend,
          locationId: data.locationId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindUserInvoicesMyInvoices = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['finances', 'findUserInvoicesMyInvoices', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices/my-invoices`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
            status: filter.status,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesFindByIdUserInvoicesSend = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdUserInvoicesSend', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices/${id}/send`,
      }),
    options
  );
};

export const useFinancesFindByIdUserInvoicesDownload = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdUserInvoicesDownload', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices/${id}/download`,
      }),
    options
  );
};

export const useFinancesFindUserInvoicesExport = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, ...queryParams } = input || {};

  return useQuery(
    ['finances', 'findUserInvoicesExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices/export`,
        params: {
          filter: {
            tenantId,
            type: filter.type,
            paymentType: filter.paymentType,
            locationId: filter.locationId,
            packageId: filter.packageId,
            between: `${filter.startDate},${filter.endDate}`,
            sentStatus: filter.sentStatus,
            search: filter.search,
            status: filter.status,
          },
          ...queryParams,
        },
      }),
    options
  );
};

export const useFinancesFindByIdUserInvoices = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdUserInvoices', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/user-invoices/${id}`,
        params: {
          include: 'payments',
          tenantId,
          filter: {},
        },
      }),
    options
  );
};

export const useFinancesUpdateUserInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/user-invoices/${id}`,
        data: {
          description: data.description,
          dueOn: data.dueOn,
          lineItems: data.lineItems,
          creditAmount: data.creditAmount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteUserInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/user-invoices/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesSendPaymentRequest = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/send-payment-request`,
        data: {
          tenantId,
          invoiceIds: data.invoiceIds,
          locationPaymentGatewayId: data.locationPaymentGatewayId,
          isImmediatePayment: data.isImmediatePayment || false,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesBulk = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/bulk-create`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          dueOn: data.dueOn,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesBulkSendUser = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/bulk-send-user`,
        data: { userIds: data.userIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesBulkSendInvoice = (options) => {
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/bulk-send-invoice`,
        data: { tenantId, invoiceIds: data.invoiceIds },
      });
    },
    onSuccess: (...res) => {
      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesBulkReverse = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/bulk-reverse`,
        data: { invoiceIds: data.invoiceIds, tenantId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateUserInvoicesGenerate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/user-invoices/generate`,
        data: {
          userId: data.userTenantId,
          dueOn: data.dueOn,
          isSend: data.isSend,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesUpdateUserInvoicesReverse = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/user-invoices/reverse/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesUpdateUserInvoicesReleaseTopUp = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/user-invoices/release-top-up/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesUpdateUserInvoicesAddToDebitBatch = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/user-invoices/${id}/add-to-debit-batch`,
        data: { debitBatchId: data.debitBatchId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindInvoiceItemTypes = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findInvoiceItemTypes', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/invoice-item-types`,
        params: {
          include: input.include || undefined,
          ...paging,
          filter: {
            tenantId: filter?.tenantId || tenantId,
          },
        },
      }),
    options
  );
};

export const useFinancesCreateInvoiceItemTypes = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/invoice-item-types`,
        data: { name: data.name, tenantId: data?.tenantId || tenantId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindByIdInvoiceItemTypes = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdInvoiceItemTypes', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/invoice-item-types/${id}`,
      }),
    options
  );
};

export const useFinancesUpdateInvoiceItemTypes = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/invoice-item-types/${id}`,
        data: { name: data.name },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeleteInvoiceItemTypes = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/invoice-item-types/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindPayments = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/payments`,
        params: {
          include: 'invoice',
          filter: {
            invoiceMemberType: filter.invoiceMemberType,
            locationId: filter.locationId,
            tenantId,
            userId: filter.userId,
            type: filter.type,
            between: `${filter.startDate},${filter.endDate}`,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useFinancesCreatePayments = (options) => {
  const queryClient = useQueryClient();
  const {
    tenantId,
    selectedLocation: { id: locationId },
  } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/payments/${data.invoiceId}`,
        data: {
          tenantId,
          locationId,
          invoiceId: data.invoiceId,
          date: data.date,
          type: data.type,
          amount: data.amount,
          reference: data.reference,
          tagId: data?.tagId ? [data.tagId] : undefined,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindPaymentsExport = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findPaymentsExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/payments/export`,
        params: {
          filter: {
            invoiceMemberType: filter.invoiceMemberType,
            tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            type: filter.type,
            between: `${filter.startDate},${filter.endDate}`,
          },
        },
      }),
    options
  );
};

export const useFinancesFindByIdPayments = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['finances', 'findByIdPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/payments/${id}`,
      }),
    options
  );
};

export const useFinancesUpdatePayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/finances/payments/${id}`,
        data: {
          date: data.date,
          type: data.type,
          amount: data.amount,
          reference: data.reference,
          tagId: data?.tagId ? [data.tagId] : undefined,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesDeletePayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/finances/payments/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreatePaymentsBulkCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/payments/bulk-create`,
        data: {
          invoiceIds: data.invoiceIds,
          date: data.date,
          type: data.type,
          reference: data.reference,
          tagIds: data.tagIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindPaystackBanks = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findPaystackBanks', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/paystack/banks`,
        params: {
          filter: { country: filter.country },
        },
      }),
    options
  );
};

export const useFinancesFindPaystackSettlements = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findPaystackSettlements', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/paystack/${filter.locationId}/settlements`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useFinancesFindPaystackSettlementsTransactions = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findPaystackSettlementsTransactions', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/paystack/settlements/transactions`,
        params: {
          filter: {
            tenantId,
            settlementId: filter.settlementId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useFinancesCreatePaystackInitializeTransaction = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/paystack/initialize-transaction`,
        data: { invoiceId: data.invoiceId, settingsId: data.settingsId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesFindPaystackVerifyTransaction = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findPaystackVerifyTransaction', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/paystack/verify-transaction`,
        params: {
          // filter: {
          //   reference: filter.reference,
          // },
          reference: filter.reference,
        },
      }),
    options
  );
};

export const useFinancesFindStatements = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findStatements', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/statements`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            between: `${filter.startDate},${filter.endDate}`,
          },
        },
      }),
    options
  );
};

export const useFinancesFindStatementsPrint = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['finances', 'findStatementsPrint', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/statements/print`,
        params: {
          filter: {
            userId: filter.userId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useFinancesFindStatementsDownload = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['finances', 'findStatementsDownload', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/statements/download`,
        params: {
          tenantId,
          userId: filter.userId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          filter: {
            // TODO: Note that this API endpoint breaks convention in having these params normally be applied as a filter.
          },
        },
      }),
    options
  );
};

export const useFinancesCreateStatementsSend = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/statements/send`,
        data: {
          tenantId,
          userId: data.userId,
          startDate: data.startDate,
          endDate: data.endDate,
          message: data.message,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateStatementsBulkSend = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/statements/bulk-send`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          startDate: data.startDate,
          endDate: data.endDate,
          message: data.message,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateStripeCustomer = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/stripe/customer`,
        data: {
          customerToken: data.customerToken,
          bankAccountToken: data.bankAccountToken,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateStripeCustomerVerifyBankAccount = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/stripe/customer/verify-bank-account`,
        data: {
          firstAmount: data.firstAmount,
          secondAmount: data.secondAmount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['finances'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useFinancesCreateStripeCreateCheckoutSession = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/stripe/create-checkout-session`,
        data: { invoiceId: data.invoiceId, settingsId: data.settingsId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};
