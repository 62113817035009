import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isCreatingAddress,
  isUpdatingAddress,
  isFetchingAddressesSearch,
  onAddressSearch,
  addressesSearchData,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        fullAddress: initialValues?.fullAddress || undefined,
        structuredAddress: initialValues?.structuredAddress || undefined,
        coordinates: {
          latitude: initialValues?.coordinates?.latitude || undefined,
          longitude: initialValues?.coordinates?.longitude || undefined,
        },
      }}
      validationSchema={Yup.object().shape({
        fullAddress: Yup.string().required(t('required')),
        structuredAddress: Yup.object(),
        coordinates: Yup.object().shape({
          latitude: Yup.string().required(t('required')),
          longitude: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, setValues }) => (
        <>
          <Row>
            <Col>
              <Field
                isAsyncSelect
                isLoading={isFetchingAddressesSearch}
                label={t('search')}
                name='search'
                options={onAddressSearch}
                placeholder=''
                onChange={({ value }) =>
                  setValues({
                    fullAddress: value.fullAddress,
                    structuredAddress: value.structuredAddress,
                    coordinates: {
                      latitude: value.coordinates.latitude,
                      longitude: value.coordinates.longitude,
                    },
                  })
                }
              />
            </Col>
          </Row>

          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Field
                  label={t('complexDetails')}
                  name='structuredAddress.complexDetails'
                />
              </Col>

              <Col>
                <Field isDisabled label={t('address')} name='fullAddress' />
              </Col>

              <Col lg={6}>
                <Field
                  isDisabled
                  label={t('latitude')}
                  name='coordinates.latitude'
                />
              </Col>
              <Col lg={6}>
                <Field
                  isDisabled
                  label={t('longitude')}
                  name='coordinates.longitude'
                />
              </Col>

              <Col>
                <Button
                  isLoading={isCreatingAddress || isUpdatingAddress}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        </>
      )}
    </Formik>
  );
};
