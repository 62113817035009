import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'name',
        Cell: ({ value }) => value,
      },
      {
        Header: t('action'),
        Cell: ({
          row: {
            original: { id, addressId },
          },
        }) => (
          <>
            {addressId && (
              <>
                <Text
                  as='span'
                  color='primary'
                  mr={2}
                  onClick={() =>
                    onClickAction({
                      action: 'update',
                      locationId: id,
                      addressId,
                    })
                  }
                >
                  {t('edit')}
                </Text>

                <Text
                  as='span'
                  color='danger'
                  onClick={() =>
                    onClickAction({
                      action: 'delete',
                      locationId: id,
                      addressId,
                    })
                  }
                >
                  {t('delete')}
                </Text>
              </>
            )}

            {!addressId && (
              <Text
                as='span'
                color='primary'
                onClick={() =>
                  onClickAction({
                    action: 'create',
                    locationId: id,
                  })
                }
              >
                {t('create')}
              </Text>
            )}
          </>
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      isVisibleHeader={false}
    />
  );
};
