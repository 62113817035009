import _ from 'lodash';
import imgLogoMark from 'octiv-assets/images/logomark.png';
import { Card, Container, Image, Text } from 'octiv-components';
import Box from 'octiv-components/box/index';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import {
  useUsersCreateDuplicateUsersMerge,
  useUsersFindDuplicateUsers,
  useUsersFindDuplicateUsersUserTenants,
} from 'octiv-hooks/requests/Users';
import React, { useEffect, useState } from 'react';

import EndOfFlow from './EndOfFlow';
import FormMergedAccounts from './FormMergedAccounts';
import FormPrimaryAccount from './FormPrimaryAccount';
import FormUpdatedAccounts from './FormUpdatedAccounts';

export default () => {
  const { isRefetchingSignedInUser, refetchSignedInUser, ...signedInUser } =
    useSignedInUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(refetchSignedInUser, []);
  const { signOut } = useSignedInUser();

  const [step, setStep] = useState(0);
  const [payload, setPayload] = useState({});

  const [primaryAccountId, setPrimaryAccountId] = useState(undefined);
  const [accountsToMerge, setAccountsToMerge] = useState([]);
  const [accountsToUpdate, setAccountsToUpdate] = useState({});

  const duplicateUsers = useUsersFindDuplicateUsers({
    primaryUserId: signedInUser?.id,
  });

  const duplicateUserTenants = useUsersFindDuplicateUsersUserTenants(
    {
      // primaryUserId: primaryAccountId,
    },
    {
      // enabled: !!primaryAccountId,
    }
  );

  const mergeUsers = useUsersCreateDuplicateUsersMerge({
    onSuccess: () => {
      setTimeout(() => {
        signOut();
      }, 5000);
    },
  });

  const unassociatedUserTenants = duplicateUserTenants.data?.filter(
    (ut) => ut?.userId !== primaryAccountId
  );

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  const checkToSkip = () => {
    const items = accountsToMerge?.filter((item) => !!item);
    const unassociatedItems = unassociatedUserTenants?.map(
      (item) => item.userId
    );

    const uniqUnassociated = _.uniq(unassociatedItems)?.sort();
    const uniqSelected = _.uniq(items)?.sort();

    const isEqual = arraysEqual(uniqUnassociated, uniqSelected);

    return isEqual;
  };

  const handleNext = () => {
    setTimeout(() => {
      if (step === 0) {
        setStep(1);
      } else if (step === 1) {
        if (checkToSkip()) {
          setStep(3);
        } else {
          setStep(2);
        }
      } else if (step === 2) {
        setStep(3);
      }
    }, 100);
  };

  const handleBack = () => {
    if (step === 3) {
      setAccountsToUpdate({});
      setStep(2);
    } else if (step === 2) {
      setAccountsToMerge([]);
      setStep(1);
    } else if (step === 1) {
      setPrimaryAccountId(undefined);
      setStep(0);
    }
  };

  const onSubmit = () => {
    mergeUsers.mutate(payload);
  };

  const hasEmptyItems = (array) => {
    return array.some((item) => _.isEmpty(item));
  };

  const hasDuplicateItems = (array) => {
    return new Set(array).size !== array.length;
  };

  useEffect(() => {
    const accountsToUpdateArray = Object.keys(accountsToUpdate).map((key) => ({
      userId: Number(key),
      email: accountsToUpdate[key],
    }));

    const tempRequestBody = {
      primaryUserId: primaryAccountId,
      mergeUserIds: _.uniqBy(
        accountsToMerge?.filter((id) => !!id),
        (value) => value
      ),
      updateUser: accountsToUpdateArray,
    };

    setPayload(tempRequestBody);
  }, [accountsToMerge, accountsToUpdate, primaryAccountId, step]);

  return (
    <Container
      appBarProps={{
        title: '',
      }}
      isLoading={false}
    >
      <Box md={6} sm={12}>
        <Card
          isFlex
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
        >
          <Image src={imgLogoMark} width='2rem' />
          <Text fontSize='1.5rem'>Merge Accounts</Text>

          <br />

          {step === 0 ? (
            <FormPrimaryAccount
              signedInUser={signedInUser}
              users={duplicateUsers?.data}
              userTenants={duplicateUserTenants?.data}
              onSubmit={(values) => {
                setPrimaryAccountId(values?.userId);
                handleNext();
              }}
            />
          ) : null}

          {step === 1 ? (
            <FormMergedAccounts
              initialValues={{
                userIds: [primaryAccountId],
              }}
              isLoading={duplicateUsers?.isFetching}
              primaryAccount={
                duplicateUsers?.data?.find(
                  (item) => item.id === primaryAccountId
                ) || signedInUser
              }
              userTenants={duplicateUserTenants?.data}
              onBack={handleBack}
              onNext={handleNext}
              onSubmit={(values) => {
                setAccountsToMerge(values?.userIds);
              }}
            />
          ) : null}

          {step === 2 ? (
            <FormUpdatedAccounts
              hasDuplicates={hasDuplicateItems(Object.values(accountsToUpdate))}
              hasEmptyFields={hasEmptyItems(Object.values(accountsToUpdate))}
              isLoading={duplicateUserTenants?.isFetching}
              primaryAccount={
                duplicateUsers?.data?.find(
                  (item) => item.id === primaryAccountId
                ) || signedInUser
              }
              primaryAccountId={primaryAccountId}
              userTenants={duplicateUserTenants?.data?.filter(
                (userTenant) => !accountsToMerge?.includes(userTenant?.userId)
              )}
              onBack={handleBack}
              onSubmit={() => {
                handleNext();
              }}
              onUpdateEmails={(values) => {
                setAccountsToUpdate((prev) => ({
                  ...prev,
                  ...values,
                }));
              }}
            />
          ) : null}

          {step === 3 ? (
            <EndOfFlow
              isLoading={mergeUsers?.isLoading}
              onBack={handleBack}
              onSubmit={onSubmit}
            />
          ) : null}
        </Card>
      </Box>
    </Container>
  );
};
