import { trim } from 'lodash';
import { Modal, ProcessingSuspense } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useToggle } from 'octiv-hooks';
import {
  useAddressesCreate,
  useAddressesDelete,
  useAddressesFindById,
  useAddressesSearch,
  useAddressesUpdate,
} from 'octiv-hooks/requests/Addresses';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const { refetchSignedInUser } = useSignedInUser();

  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [address, setAddress] = useState(undefined);
  const callbackRef = useRef();
  const [toggle, setToggle, resetToggle] = useToggle();

  const addressesSearch = useAddressesSearch(
    {
      address,
    },
    {
      enabled: false,
    }
  );

  const addressesFindByIdRequest = useAddressesFindById(
    {
      id: toggle.data?.addressId,
    },
    {
      enabled: !!toggle.data?.addressId,
    }
  );

  const postAddressesRequest = useAddressesCreate({
    onSuccess: () => {
      resetToggle();
      refetchSignedInUser();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const putAddressesRequest = useAddressesUpdate({
    onSuccess: () => {
      resetToggle();
      refetchSignedInUser();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const deleteAddressesRequest = useAddressesDelete({
    onSuccess: () => {
      resetToggle();
      refetchSignedInUser();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onAddressSearch = (inputValue, callback) => {
    callbackRef.current = callback;
    setAddress(inputValue);
  };

  React.useEffect(() => {
    const timeout = setTimeout(async () => {
      if (trim(address)?.length >= 3) {
        const response = await addressesSearch.refetch();

        callbackRef.current(
          response?.data?.map((addressSearch) => ({
            label: addressSearch.fullAddress,
            value: addressSearch,
          }))
        );
      }
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleSubmit = (values) => {
    if (!toggle.data.addressId) {
      postAddressesRequest.mutate({
        type: 'location',
        id: toggle.data.locationId,
        ...values,
      });
    } else {
      putAddressesRequest.mutate({ id: toggle.data.addressId, ...values });
    }
  };

  const onClickAction = ({ action, locationId, addressId }) => {
    switch (action) {
      case 'create':
        setToggle({
          type: 'create',
          data: { locationId },
        });
        break;

      case 'update':
        setToggle({
          type: 'update',
          data: { locationId, addressId },
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteAddressesRequest.mutate({ id: addressId });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Setting title={t('locationAddresses')}>
      {toggle.isVisible && (
        <Modal isSidebar title={t('address')} onClose={resetToggle}>
          {addressesFindByIdRequest.isFetching ? (
            <ProcessingSuspense />
          ) : (
            <>
              <Form
                addressesSearchData={addressesSearch.data}
                initialValues={addressesFindByIdRequest.data || {}}
                isCreatingAddress={postAddressesRequest.isLoading}
                isFetchingAddressesSearch={addressesSearch.isFetching}
                isUpdatingAddress={putAddressesRequest.isLoading}
                onAddressSearch={onAddressSearch}
                onSubmit={handleSubmit}
              />
              <div />
            </>
          )}
        </Modal>
      )}

      <Table
        hasAlternateBackground
        data={locations}
        onClickAction={onClickAction}
      />
    </Setting>
  );
};
